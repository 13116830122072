<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>

      <v-card outlined class="mt-2">
        <v-toolbar flat color="grey lighten-3" height="auto">
          <v-list-item-content>
            <v-list-item-title class="title text-wrap">
              <v-row>
                <v-col cols="2">{{ dog.name }}</v-col>
                <v-col cols="10">
                  <v-btn
                    small
                    depressed
                    class="mr-2"
                    color="blue blue--text lighten-4"
                    :loading="pdfLoading2"
                    @click="downloadPedigreePdf"
                    >Pedigree</v-btn
                  >

                  <v-btn
                    small
                    depressed
                    class="mr-2"
                    color="green green--text lighten-4"
                    :loading="pdfLoading"
                    @click="downloadPdf"
                    v-if="
                      dog.status == 'breeding-dog' &&
                      dog.location == 'fch' &&
                      dog.gender == 'M'
                    "
                    >Family Care Home Contract</v-btn
                  >

                  <v-btn
                    small
                    depressed
                    class="mr-2"
                    color="green green--text lighten-4"
                    :loading="pdfFCHContract3Loading"
                    @click="downloadFamilyCareHomeContract3Pdf"
                    v-if="
                      dog.status == 'breeding-dog' &&
                      dog.location == 'fch' &&
                      dog.gender == 'F'
                    "
                    >Family Care Home Contract (3)</v-btn
                  >

                  <v-btn
                    small
                    depressed
                    class="mr-2"
                    color="green green--text lighten-4"
                    :loading="pdfFCHContract4Loading"
                    @click="downloadFamilyCareHomeContract4Pdf"
                    v-if="
                      dog.status == 'breeding-dog' &&
                      dog.location == 'fch' &&
                      dog.gender == 'F'
                    "
                    >Family Care Home Contract (4)</v-btn
                  >

                  <v-btn
                    small
                    depressed
                    class="mr-2"
                    color="orange orange--text lighten-4"
                    :loading="pdfLoading1"
                    @click="downloadWormingPdf"
                    v-if="dog.status == 'breeding-dog' || dog.status == 'puppy'"
                  >
                    Worming Record</v-btn
                  >

                  <v-btn
                    small
                    depressed
                    class="mr-2"
                    color="green green--text lighten-4"
                    :loading="pdfLoadingContractOfSale"
                    @click="downloadContractOfSalePdf"
                    v-if="dog.status == 'puppy'"
                  >
                    Contract of Sale</v-btn
                  >

                  <v-btn
                    small
                    depressed
                    class="mr-2"
                    color="red red--text lighten-4"
                    :loading="pdfTemperamentAssessment"
                    @click="downloadTemperamentAssessmentPdf"
                    v-if="dog.status == 'puppy'"
                  >
                    Temperament Assessment</v-btn
                  >
                </v-col></v-row
              >
            </v-list-item-title>
          </v-list-item-content>

          <template v-slot:extension>
            <v-tabs dark background-color="grey darken-2">
              <v-tab
                :to="{
                  name: `module-lhl-dogs-individual`,
                }"
                >Basic Details</v-tab
              >
              <v-tab
                :to="{
                  name: `module-lhl-dogs-individual-weight`,
                }"
                >Weight</v-tab
              >
              <v-tab
                v-if="dog.status == 'breeding-dog' && dog.gender == 'F'"
                :to="{
                  name: `module-lhl-dogs-individual-seasons`,
                }"
                >Seasons</v-tab
              >
              <v-tab
                v-if="dog.status !== 'puppy'"
                :to="{
                  name: `module-lhl-dogs-individual-litters`,
                }"
                >Litters</v-tab
              >
              <v-tab
                v-if="
                  dog.status == 'breeding-dog' ||
                  dog.status == 'breeding-complete'
                "
                :to="{
                  name: `module-lhl-dogs-individual-offspring`,
                }"
                >Offspring</v-tab
              >

              <v-tab
                v-if="dog.location == 'fch'"
                :to="{
                  name: `module-lhl-dogs-individual-fch`,
                }"
                >FCH</v-tab
              >

              <v-tab
                :to="{
                  name: `module-lhl-dogs-individual-worming`,
                }"
                >Worming</v-tab
              >
              <v-tab
                :to="{
                  name: `module-lhl-dogs-individual-health`,
                }"
                >Health</v-tab
              >
              <v-tab
                :to="{
                  name: `module-lhl-dogs-individual-testing`,
                }"
                >Testing</v-tab
              >
              <v-tab
                v-if="dog.status !== 'puppy'"
                :to="{
                  name: `module-lhl-dogs-individual-images`,
                }"
                >Website/Images</v-tab
              >
              <v-tab
                v-if="dog.status !== 'puppy'"
                :to="{
                  name: `module-lhl-dogs-individual-pedigree`,
                }"
                >Pedigree</v-tab
              >
            </v-tabs>
          </template>
        </v-toolbar>

        <v-divider></v-divider>
        <div class="pa-5">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </v-card>

      <v-card outlined class="mt-2" v-if="dog.gender == 'F'">
        <v-tabs
          dark
          background-color="grey darken-2"
          v-model="tab"
          @change="onTabChange"
        >
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentTab">
          <v-tab-item>
            <v-card>
              <v-card-text
                ><v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-card outlined class="mb-6">
                        <v-card-title
                          class="d-flex justify-start align-center grey lighten-3"
                        >
                          <div class="flex-grow-1">Bitch</div>
                        </v-card-title>
                        <v-data-table
                          :headers="tableHeaders"
                          :items="tasks_bitch"
                          no-data-text="No Tasks"
                          :items-per-page="-1"
                        >
                          <template v-slot:item.task="{ item }">
                            {{ item.task.name }}
                          </template>

                          <template v-slot:item.status="{ item }">
                            <v-switch
                              v-model="item.status"
                              @click="openSwitch(item)"
                            ></v-switch>
                          </template>

                          <template v-slot:item.complete_by_date="{ item }">
                            {{ formatDate(item.complete_by_date) }}
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-card outlined class="mb-6">
                        <v-card-title
                          class="d-flex justify-start align-center grey lighten-3"
                        >
                          <div class="flex-grow-1">Pregnancy</div>
                        </v-card-title>
                        <v-data-table
                          :headers="tableHeaders"
                          :items="tasks"
                          no-data-text="No Tasks"
                          :items-per-page="-1"
                        >
                          <template v-slot:item.task="{ item }">
                            {{ item.task.name }}
                          </template>

                          <template v-slot:item.status="{ item }">
                            <v-switch
                              v-model="item.status"
                              @click="openSwitch(item)"
                            ></v-switch>
                          </template>

                          <template v-slot:item.complete_by_date="{ item }">
                            {{ formatDate(item.complete_by_date) }}
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container></v-card-text
              >
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Lomond Hills Labradoodles",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Dogs",
          disabled: false,
          exact: true,
          to: {
            name: "module-lhl-dogs-all",
            params: { id: this.$route.params.id },
          },
        },
      ],
      deleteAddressDialog: {
        open: false,
        error: false,
        addressLine1: "",
        addressId: null,
        loading: false,
      },
      addressDialog: {
        open: false,
        errors: {},
        fields: {
          line_1: "",
          line_2: "",
          line_3: "",
          city: "",
          county: "",
          postcode: "",
          country: "",
          is_shipping: false,
          is_billing: false,
        },
        loading: false,
        isEditing: false,
        address: {},
      },
      fields: {
        status: false,
      },
      editCustomerDialog: {
        open: false,
        errors: {},
        first_name: "",
        last_name: "",
        email: "",
        phone: null,
        is_breeder: false,
        marketing_opt_in: false,
        loading: false,
      },
      snackbar: {
        open: false,
        text: "",
        timeout: 4000,
      },
      changingDelivery: null,
      changingBilling: null,
      deleteBreed: {
        dialog: false,
        item: null,
        model: {},
        loading: false,
      },
      pdfLoading: false,
      pdfLoading1: false,
      pdfLoading2: false,
      pdfLoadingContractOfSale: false,
      pdfTemperamentAssessment: false,
      pdfFCHContract3Loading: false,
      pdfFCHContract4Loading: false,
      tab: null,
      currentTab: null,
      items: ["Bitch", "Pregnancy"],
      tableHeaders: [
        { text: "Name", value: "task" },
        { text: "Status", value: "status" },
        { text: "For", value: "complete_by_date" },
      ],
    };
  },

  beforeRouteUpdate(to, from, next) {
    this.$store.commit("showHideLoader", true);
    Promise.all([
      this.$store.dispatch("lhl/dogs/loadDog", {
        appId: to.params.id,
        dogId: to.params.dogId,
      }),
      this.$store.dispatch("lhl/customers/loadCustomers", {
        appId: to.params.id,
      }),
    ]).then(() => {
      this.$store.commit("showHideLoader", false);
      next();
    });

    next();
  },

  computed: {
    dog() {
      return this.$store.getters["lhl/dogs/get"];
    },

    tasks_bitch() {
      let dog = this.$store.getters["lhl/dogs/get"];

      let tasks = dog.tasks.filter((c) => {
        return c.task.task_category == 1;
      });

      return tasks;
    },

    tasks() {
      let dog = this.$store.getters["lhl/dogs/get"];

      let tasks = dog.tasks.filter((c) => {
        return c.task.task_category == 2;
      });

      return tasks;
    },
  },

  methods: {
    onTabChange() {
      this.currentTab = this.tab;
    },

    openSwitch(task) {
      this.loading = true;
      this.errors = {};

      this.fields.status = task.status ? 1 : 0;

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        taskId: task.id,
      };

      this.$store
        .dispatch("lhl/dogs/saveTaskStatus", payload)
        .then(() => {})
        .catch();
    },

    downloadPedigreePdf() {
      const appId = this.$route.params.id;

      const dogId = this.$route.params.dogId;
      this.pdfLoading2 = true;

      this.$store
        .dispatch("lhl/dogs/downloadPedigreePdf", {
          appId,
          dogId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);

          this.pdfLoading2 = false;
        })
        .catch(() => {
          this.pdfLoading2 = false;
        });
    },

    downloadFamilyCareHomeContractPdf() {
      const appId = this.$route.params.id;

      const dogId = this.$route.params.dogId;
      this.pdfLoading2 = true;

      this.$store
        .dispatch("lhl/dogs/downloadPedigreePdf", {
          appId,
          dogId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);

          this.pdfLoading2 = false;
        })
        .catch(() => {
          this.pdfLoading2 = false;
        });
    },

    downloadFamilyCareHomeContract3Pdf() {
      const appId = this.$route.params.id;

      const dogId = this.$route.params.dogId;
      this.pdfFCHContract3Loading = true;

      this.$store
        .dispatch("lhl/dogs/downloadFamilyCareHomeContract3Pdf", {
          appId,
          dogId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);

          this.pdfFCHContract3Loading = false;
        })
        .catch(() => {
          this.pdfFCHContract3Loading = false;
        });
    },

    downloadFamilyCareHomeContract4Pdf() {
      const appId = this.$route.params.id;

      const dogId = this.$route.params.dogId;
      this.pdfFCHContract4Loading = true;

      this.$store
        .dispatch("lhl/dogs/downloadFamilyCareHomeContract4Pdf", {
          appId,
          dogId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);

          this.pdfFCHContract4Loading = false;
        })
        .catch(() => {
          this.pdfFCHContract4Loading = false;
        });
    },

    downloadTemperamentAssessmentPdf() {
      const appId = this.$route.params.id;

      const dogId = this.$route.params.dogId;
      this.pdfTemperamentAssessment = true;

      this.$store
        .dispatch("lhl/dogs/downloadTemperamentAssessmentPdf", {
          appId,
          dogId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);

          this.pdfTemperamentAssessment = false;
        })
        .catch(() => {
          this.pdfTemperamentAssessment = false;
        });
    },

    downloadWormingPdf() {
      const appId = this.$route.params.id;

      const dogId = this.$route.params.dogId;
      this.pdfLoading1 = true;

      this.$store
        .dispatch("lhl/dogs/downloadWormingPdf", {
          appId,
          dogId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);

          this.pdfLoading1 = false;
        })
        .catch(() => {
          this.pdfLoading1 = false;
        });
    },

    downloadContractOfSalePdf() {
      const appId = this.$route.params.id;

      const dogId = this.$route.params.dogId;
      this.pdfLoadingContractOfSale = true;

      this.$store
        .dispatch("lhl/dogs/downloadContractOfSalePdf", {
          appId,
          dogId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);

          this.pdfLoadingContractOfSale = false;
        })
        .catch(() => {
          this.pdfLoadingContractOfSale = false;
        });
    },

    downloadPdf() {
      const appId = this.$route.params.id;

      const dogId = this.$route.params.dogId;
      this.pdfLoading = true;

      this.$store
        .dispatch("lhl/dogs/downloadFamilyCarePdf", {
          appId,
          dogId,
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);

          this.pdfLoading = false;
        })
        .catch(() => {
          this.pdfLoading = false;
        });
    },
  },
};
</script>
